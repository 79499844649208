import { teacherListWithAdmin } from '@/api/user/admin'
import { Class, User } from 'momai'
import { Checkbox } from 'vant'
import { ref, watch, Ref, computed } from 'vue'

export default (classForm: Ref<Class>) => {
  const searchKeyword = ref('')
  const teachers = ref<User[]>([])
  const teachersAfterMatchKeyword = computed(() => {
    const k = searchKeyword.value
    const l = teachers.value
    if (k === '') {
      return l
    }
    return l.filter(u => u.username.includes(k))
  })
  const checkedTeacherIds = ref<number[]>([])
  const setCheckedTeacher = () => {
    const ids = classForm.value.userId.split(',')
    checkedTeacherIds.value = teachers.value.filter(t => ids.some(id => Number(id) === t.id)).map(t => t.id)
  }
  watch([classForm, teachers], setCheckedTeacher, { deep: true })
  // const teacherCheckboxs = ref<Checkbox[]>([])
  const teacherCheckboxs = ref<any[]>([])
  const checkedTeachers = computed(() => teachers.value.filter(t => checkedTeacherIds.value.some(id => id === t.id)))
  const showTeacherPicker = ref(false)

  teacherListWithAdmin().then(r => {
    teachers.value = r
  })

  return {
    searchKeyword,
    checkedTeachers,
    teachersAfterMatchKeyword,
    showTeacherPicker,
    checkedTeacherIds,
    teacherCheckboxs,
    toggleTeacher: (i: number) => {
      teacherCheckboxs.value[i].toggle()
    }
  }
}
