
import { Class, createClass } from 'momai'
import { ref } from 'vue'
import { detail } from '@/api/entity/class'
import { useRoute } from 'vue-router'
import ClassForm from '../Form/Index.vue'

export default {
  components: { ClassForm },
  setup: () => {
    const classInfo = ref<Class>(createClass())
    const { classId } = useRoute().params

    detail(Number(classId)).then(r => {
      classInfo.value = r
    })

    return {
      classInfo
    }
  }
}
