
import { Class, createClass } from 'momai'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { save } from '@/api/entity/class'
import { courseSeriesList } from '@/states/couseSeries'
import { Toast } from 'vant'
import useTeacher from './useTeacher'
import { fetchAllClasses } from '@/states/classes'

export default defineComponent({
  props: {
    classInfo: {
      type: Object as PropType<Class>,
      required: true
    },
    isCreate: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, { emit }) => {
    const classForm = ref<Class>(props.classInfo)

    const setFirstCourseSeriesId = () => {
      const list = courseSeriesList.value
      if (!classForm.value.courseId && list.length > 0) {
        classForm.value.courseId = list[0].id
      }
    }

    setFirstCourseSeriesId()

    watch(courseSeriesList, setFirstCourseSeriesId)

    const showCourseSeriesPicker = ref(false)

    const selectedCourseSeires = computed({
      get: () => courseSeriesList.value.find(({ id }) => id === classForm.value.courseId)?.courseName,
      set: v => {
        classForm.value.courseId = Number(v)
      }
    })

    watch(() => props.classInfo, newClassInfo => {
      classForm.value = newClassInfo
    }, {
      deep: true
    })

    watch(classForm, newClassForm => {
      emit('update:classInfo', newClassForm)
    }, {
      deep: true
    })
    const { checkedTeacherIds, ...others } = useTeacher(classForm)
    return {
      ...others,
      checkedTeacherIds,
      classForm,
      selectedCourseSeires,
      showCourseSeriesPicker,
      courseSeriesList,
      saveClassInfo: () => {
        const form = classForm.value
        form.userId = checkedTeacherIds.value.join(',')
        if (!form.className) {
          Toast.fail('请输入班级名称')
          return
        }
        save(classForm.value).then(() => {
          if (props.isCreate) {
            classForm.value = createClass()
          }
          fetchAllClasses()
          Toast.success('保存成功')
        })
      }
    }
  }
})
